@import "../../variables.scss";

.race-view-header {
  background: $secondary-background;
  width: 100%;
  color: $secondary-text;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1.4rem;

  p {
    margin: 0;
  }
  p#{&}__subtitle {
    font-size: 0.8rem;
    margin-top: 10px;
  }
}

.race-view {

  max-width: 50rem;
  margin: 0 auto;

  &__elapsed {
    font-size: 2.5rem;
    margin: 0;
    text-align: center;
    &__key {
      display: block;
      font-size: 0.8rem;
    }
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
  }

  &__top-data {
    padding: 20px;
    display: inline-block;
    flex: 1 1 50%;
    text-align: center;
    color: $secondary-text;

    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      top: 10px;
      bottom: 10px;
      right: 10px;
      background: $secondary-background;
      box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.25);
      z-index: -1;
    }
  }
  &__key {
    font-size: 1rem;
    text-decoration: underline;
    margin-bottom: 0.75rem;
  }
  &__value {
    font-size: 1.9rem;
  }
  &__sub-data {
    font-size: 0.8rem
  }
}

.reverse {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border: 1.5px solid $text;
  background: transparent;
  justify-content: center;
  margin: 10px 0 10px 30px;
  &__up {
    margin-bottom: -2px;
    opacity: 0.2;
  }
  &__down {
    margin-top: -2px;
  }

  &--reverse &__up { opacity: 1; }
  &--reverse &__down { opacity: 0.2; }

}

.tabs {
  display: flex;
  border-bottom: 1.5px solid $text;
  padding: 0 10px;
  margin-top: 40px;

  &__button {
    border: 1.5px solid $text;
    background: $background;
    color: $text;
    padding: 10px;
    margin-bottom: -1.5px;
    font-size: 1.5rem;

    &:not(:first-child) {
      border-left-width: 0;
    }
    &--selected {
      border-bottom-color: $background;
    }
  }

  &__panel {
    padding: 20px 0;
  }
}
