@import "./variables.scss";

body {
  background: $background;
  color: $text;
  font-family: 'Roboto', sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  max-height: 100vh;
}

.sr-only {
  position: absolute;
  overflow: hidden;
  left: -1000px;
  width: 1px;
  height: 1px;
  display: block;
}

* {
  box-sizing: border-box;
}
