.admin {

  &__top {
    display: flex;
    justify-content: space-between;
    & .race-view__sub-data {
      font-size: .6em;
    }
  }

  &__placeholder {
    height: 25vh;
  }
  &__wrapper {
    width: 100vw;
    height: 25vh;
    display: flex;

  }
  &__wrapper button {
    flex: 1;
    background: lavender;
    font-size: 20px;
    border: 3px solid lightblue;
  }

  &__select {
    display: block;
    width: 100%;
    font-size: 2em;
  }
}

.login {
  input, button {
    display: block;
    width: calc(100% - 40px);
    margin: 20px;
  }
  input {
    font-size: 1.5em;
  }

  button {
    font-size: 2em;
  }
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 0, 0, 0.5);

  button {
    display: block;
    width: calc(100% - 20px);
    height: 100px;
    margin: 10px;
  }
}

.temp-disable {
  &:disabled {
    opacity: 0.3;
  }
}
