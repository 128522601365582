@import "../../variables.scss";

.table {
  width: 100%;
  border-collapse: collapse;
  td, th {
    padding: 10px;
    text-align: right;
  }

  th {
    font-size: 1.2em;
    position: sticky;
    top: 0;
    background: $background;
    z-index: 1;
  }

  &__current-row {
    opacity: 0.5;
  }

  tr:nth-child(2n) {
    background: $shaded-background;
  }
}
